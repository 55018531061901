.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
/* Styles for the circular image with specific size */
.circular-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover; /* Ensures the image covers the container without distortion */
}

/* Styles for the card to ensure it's centered both horizontally and vertically */
.centered-card {
  width: 30vw; /* Adjust as needed */
  margin: auto; /* Center the card horizontally */
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Example shadow */
  border-radius: 8px; /* Example border radius */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  text-align: start;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh; /* Full viewport height */
}
/* Styles to center the button both horizontally and vertically */
.center-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}


.title {
  text-align: center;
  /* padding-top: 25px; */
  font-size: 30px;
  font-family: "Open Sans", sans-serif;
    font-weight: 400;
}

.public_card {
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  width: 300px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.card-body {
  text-align: start;
}

.card-title {
  font-size: 1.50rem;
  font-weight: bold;
}

.links a {
  display: block;
  color: #3182ce;
  transition: color 0.2s;
}

.links a:hover {
  text-decoration: underline;
}

/* nav  */
.custom-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding: 2rem 2rem; /* Add a gap of 1rem from top and 2rem from both sides */
}

.Modal_title {
  font-size: 24px; /* or any other size you prefer */
  font-weight: bold;
}

.card-actions{
  justify-content: end;
}
/* some public page css  */

.public_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height to center from top as well */
  background-color:hsla(264,100%,80%,1);
  background-image:
  radial-gradient(at 63% 36%, hsla(294,73%,69%,1) 0px, transparent 50%),
  radial-gradient(at 33% 77%, hsla(5,90%,60%,1) 0px, transparent 50%),
  radial-gradient(at 76% 53%, hsla(349,77%,79%,1) 0px, transparent 50%),
  radial-gradient(at 9% 80%, hsla(247,95%,64%,1) 0px, transparent 50%),
  radial-gradient(at 73% 32%, hsla(352,66%,66%,1) 0px, transparent 50%);
}

.public_center-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Card size */
.public_centered-card {
  max-width: 400px; /* Adjust the value to set the desired fixed width */
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(7.5px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

@media (max-width: 443px) {
  .public_centered-card {
    max-width: 350px;
  }
}

.public_card-body {
  padding-top: 1rem;
  text-align: justify;
}

.public_card-title {
  font-size: 28px;
  font-weight: bold;
  padding-bottom: 1rem;
  text-align: center;
}

.public_circular-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto;
  display: block;
}

/* Icon grid */
.public_links {
  padding-top: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
  gap: 10px;
  justify-content: center;
  align-items: center; /* Add this line */
  margin: 0 auto;
}

/* Square button styles */
.public_square-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  /* margin: 10px; */
  border: 1px solid transparent; /* Change the border to transparent */
  border-radius: 10px;
  text-align: center;
  text-decoration: none;
  color: #000;
  font-size: 24px;
  background-color: rgba(240, 240, 240, 0.5); /* Change the background color to a semi-transparent shade */
}

.public_square-button:hover {
  background-color: rgba(224, 224, 224, 0.7); /* Change the hover background color to a semi-transparent shade */
}
/* Icon size */
.public_square-button svg {
  font-size: 16px; /* Adjust the value to change the icon size */
}

.spacer{
  height: 15px;
}

